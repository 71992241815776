import React, { useEffect, useState } from 'react';
import {
 Box, Button, CircularProgress, Grid, TextField
} from '@mui/material';
import {
 cartCouponUpdate, cartDiscountUpdate, clearCoupon, clearDiscount
} from '@/store/cart/cartActions';
import { connect } from 'react-redux';
import useNotify from '@/hooks/useNotify';
import { apiRequest } from '@/util/util';
import { API_COUPON_APPLY, CURRENCY_SYMBOL } from '@/util/constants';


function Coupon({ ...otherProps }) {

  const [notify] = useNotify();
  const [coupon, setCoupon] = useState(otherProps.currentCoupon);
  const [loading, setLoading] = useState(false);
  const [btnCouponTxt, setCouponTxt] = useState('Apply Coupon');
  const [lastDiscount, setLastDiscount] = useState(() => ({ ...otherProps.discount }));

  const couponHandler = (e) => {
    setCoupon(e.target.value);
  }

  useEffect(() => {
    if (coupon !== '') {
      document.getElementById('coupon').disabled = true;
    }
  }, [setCoupon]);

  const removeCouponHandler = () => {

    setCoupon('');

    document.getElementById('coupon').disabled = false;

    otherProps.clearCoupon();

    // re-apply last used discount if applicable
    if (lastDiscount.id && (lastDiscount.value !== otherProps.discount.value)) {
      otherProps.cartDiscountApply(lastDiscount);
      notify.info('Your discount restored!');
    }
  }

  const handleCouponApply = async () => {

    if (coupon === '') {
      notify.warning('Please fill-up Coupon Box');
      return;
    }

    // check if discount should be removed
    const removeDiscount = shouldRemoveDiscount();

    setLoading(true);
    setCouponTxt('Process...');

    // when we remove discount then no need to subtract discount amount in total for coupon
    const discountValue = removeDiscount ? 0 : otherProps.discount.value;

    const totalItemCost = (
      (otherProps.totalItemCost + otherProps.totalAddonsCost)
      - (discountValue + otherProps.currentPointsAmount)
    );

    if (totalItemCost <= 0) {
      notify.warning(`Can't apply coupon when total amount is ${CURRENCY_SYMBOL}0`);
      setLoading(false);
      setCouponTxt('Apply Coupon');
      return;
    }

    const couponObject = {
      total: totalItemCost.toFixed(2),
      order_type: otherProps.orderType.toLowerCase(),
      code: coupon,
    };

    try {

      if (couponObject.code === otherProps.currentCoupon) {

        setLoading(false);
        setCouponTxt('Apply Coupon');
        notify.info('Coupon already applied!');


      } else {
        const response = await apiRequest.post(API_COUPON_APPLY, couponObject);
        const coupon = response.data.data;

        if (response.data.status) {

          // remove discount if it not set to be applied with coupons
          if (removeDiscount) {
            otherProps.cartDiscountClear();
            notify.info('Discount removed as you applied coupon, Remove coupon to restore discount!');
          }

          setLoading(false);
          setCouponTxt('Apply Coupon');

          const couponDispatchObject = {
            id: coupon.id,
            value: coupon.value,
            code: couponObject.code,
            type: coupon.type,
            amount: coupon.amount.toFixed(2),
          };

          otherProps.cartCouponUpdate(couponDispatchObject);

          notify.success('Coupon Applied!!');

          document.getElementById('coupon').disabled = true;
        } else {

          setLoading(false);
          setCouponTxt('Apply Coupon');
          notify.error(response.data.message);
        }
      }


    } catch (e) {

      notify.error('Invalid Coupon');
      setLoading(false);
      setCouponTxt('Apply Coupon');
    }

  }

  function shouldRemoveDiscount() {

    // find applied discount
    const discountId = otherProps.discount.id;
    const appliedDiscount = otherProps.discounts.find((d) => d.id === discountId);

    // discount not applied
    if (!appliedDiscount) return false;

    // discount type is set / not set with coupon
    const shouldRemove = !(appliedDiscount.discount_applicable);

    // save last used discount
    if (shouldRemove) setLastDiscount({ ...otherProps.discount });

    return shouldRemove;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={4}>

        <form noValidate>
          <TextField
            type="text"
            variant="outlined"
            margin="dense"
            fullWidth
            name="time"
            label="Use Coupon Code"
            id="coupon"
            autoComplete="off"
            value={coupon}
            onChange={couponHandler}
          />

          <Box style={{ marginBottom: 5, marginTop: 7 }}>
            <Button
              color="primary"
              variant="outlined"
              disabled={loading}
              onClick={() => handleCouponApply()}
            >

              {
                loading
                && <CircularProgress color="primary" size={25} />
              }

              {btnCouponTxt}
            </Button>

            <Button
              color="secondary"
              variant="outlined"
              onClick={() => removeCouponHandler()}
              style={{ marginLeft: 5 }}
            >
              Remove Coupon
            </Button>

          </Box>


        </form>

      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderType: state.cart.order.type,
  discount: state.cart.discount,
  discounts: state.offer.data.discounts,
  currentCoupon: state.cart.coupon.code,
  currentPointsAmount: state.cart.pointsToMoney,
});

const mapDispatchToProps = (dispatch) => ({
  cartCouponUpdate: (coupon) => dispatch(cartCouponUpdate(coupon)),
  clearCoupon: () => dispatch(clearCoupon()),
  cartDiscountApply: (discount) => dispatch(cartDiscountUpdate(discount)),
  cartDiscountClear: () => dispatch(clearDiscount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
