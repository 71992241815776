import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { logOut } from '../../store/user/userActions';
import { connect } from 'react-redux';
import { clearCart } from '../../store/cart/cartActions';
import NavItem from '../../components/nav/navItem';
import routes from '../../util/routes';
import { isMobile } from 'react-device-detect';

function Logout({ name, mobileMenuToggleRef, ...otherProps }) {

  const navigate = useNavigate();

  useEffect(() => {

    if (!otherProps.isLoggedIn) {
      navigate(routes.login);
    }

  }, [otherProps.lastUser]);

  const logoutHandler = () => {

    if (otherProps.isLoggedIn) {
      otherProps.clearCart();
      otherProps.logOut();
      window?.JSInterface?.forceWindowRelaod('hello');
    }

    // close menu
    if (isMobile) {
      mobileMenuToggleRef?.current?.click();
    }

  };

  return (
    <li className="nav-item" onClick={logoutHandler}>
      <Link to="" className="nav-link">{name}</Link>
    </li>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  lastUser: state.user.lastUser
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
  clearCart: () => dispatch(clearCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);

NavItem.prototypes = {
  name: PropTypes.string.isRequired
};
