import { useEffect, useReducer, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Alert,
  CircularProgress,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { connect } from 'react-redux';
import { footerShowHide, headerShowHide } from '../../store/layout/layoutActions';
import { apiRequest } from '../../util/util';
import routes from '../../util/routes';
import { AUTH_ENDPOINT_RESET_PASSWORD } from '../../util/constants';
import { formValidator, formSingleValidator, validationRules } from '../../util/formValidation';
import Copyright from '../../components/util/copyright';
import loginImage from '../../assets/images/login.jpg';
import _ from 'lodash';

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    email: false,
    password: false,
    password_confirmation: false
  }
};

function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) || 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function ResetPassword(props) {

  const navigate = useNavigate();


  // redirect if user logged in
  // useEffect(() => {
  //   if (props.isLoggedIn) {
  //     navigate(routes.home);
  //   }
  // }, [props.isLoggedIn, props.user]);

  const styles = {
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `url('${loginImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        (theme) => (theme.palette.mode === 'light' ? (theme) => theme.palette.grey[50] : (theme) => theme.palette.grey[900]),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: (theme) => theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: (theme) => theme.spacing(1),
    },
    submit: {
      margin: (theme) => theme.spacing(3, 0, 2),
    },
  };

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  // hide/show header footer
  useEffect(() => {
    // hiding header footer
    toggleHeaderFooter(false);

    // set email from url
    setCredentials({
      ...credentials,
      email: getUrlData().email
    });

    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  // check if the token is correct

  const toggleHeaderFooter = (show) => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }

  // handle form inputs
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    password_confirmation: ''
  });

  const validationSchema = {
    email: [
      validationRules.required(),
      validationRules.email()
    ],
    password: [
      validationRules.required(),
      validationRules.min('8'),
      validationRules.max([16]),
    ],
    password_confirmation: [
      validationRules.required(),
      validationRules.matchWithField(['password', credentials.password])
    ]
  };

  const getLocaltion = useLocation();

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // update state
    setCredentials({
      ...credentials,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);

      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();

  };

  const getUrlData = () => {
    const token = getLocaltion.pathname.split('/').pop();
    const email = decodeURIComponent(getLocaltion.search.substr(1));

    return { token, email };
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // all field validation before submit
    const { error, data } = await formValidator(credentials, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }

    try {

      errorDispatch({
        type: errorTypes.SUBMIT
      });

      const urlData = getUrlData();
      const res = await apiRequest.post(AUTH_ENDPOINT_RESET_PASSWORD, {
        token: urlData.token,
        email: urlData.email,
        password: credentials.password,
        password_confirmation: credentials.password_confirmation
      });

      errorDispatch({
        type: errorTypes.SUBMIT_SUCCESS,
        payload: res.data.message
      });

      setTimeout(() => {
        navigate(routes.login);
      }, 3000);

    } catch (e) {
      const err = e?.response?.data?.errors;

      errorDispatch({
        type: errorTypes.SUBMIT_FAIL,
        payload: err?.email?.[0] || err?.token?.[0] || err?.password?.[0] || e?.response?.data?.message
      });
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={styles.root}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <CssBaseline />
      <Box className=" col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-3" component={Paper} elevation={6}>
        <Box sx={styles.paper}>

          <Avatar sx={styles.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>

          {
            errorState.error
            && <Alert severity="error" variant="standard">{errorState.message}</Alert>
          }

          {
            errorState.success
            && <Alert severity="success" variant="standard">{errorState.message}</Alert>
          }


          <Box
            component="form"
            sx={styles.form}
            noValidate
            onSubmit={submitHandler}
          >
            <TextField
              type="email"
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              error={!!errorState.fields.email}
              helperText={errorState.fields.email}
              value={credentials.email}
              onChange={changeHandler}
            />

            <TextField
              type="password"
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="new-password"
              label="New Password"
              name="password"
              autoFocus
              error={!!errorState.fields.password}
              helperText={errorState.fields.password}
              value={credentials.password}
              onChange={changeHandler}
            />

            <TextField
              type="password"
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="confirm-password"
              label="Confirm Password"
              name="password_confirmation"
              error={!!errorState.fields.password_confirmation}
              helperText={errorState.fields.password_confirmation}
              value={credentials.password_confirmation}
              onChange={changeHandler}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={styles.submit}
              disabled={errorState.loading}
            >
              Save New Password
              {
                errorState.loading
                && <CircularProgress size="20px" style={{ marginLeft: '10px' }} />
              }
            </Button>

            <Grid container>
              <Grid item xs>
                <RouterLink to={routes.login}>
                  Back to Login
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink to={routes.signup}>
                  Don't have an account? Sign Up
                </RouterLink>
              </Grid>
            </Grid>
            {/*<Box mt={5}>*/}
            {/*  <Copyright />*/}
            {/*</Box>*/}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  lastUser: state.user.lastUser
});

const mapDispatchToProps = (dispatch) => ({
  showHideHeader: (shouldShow) => dispatch(headerShowHide(shouldShow)),
  showHideFooter: (shouldShow) => dispatch(footerShowHide(shouldShow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
