import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
 Box, List, ListItem, Checkbox
} from '@mui/material';
import useStyles from './addToCartAddons.style';
import { connect } from 'react-redux';
import { CURRENCY_SYMBOL } from '../../util/constants';

function SetMenuAddons({
  item, currentItem, selectHandler, ...otherProps
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [itemWithExtraPrice, setItemWithExtraPrice] = useState([]);

  useEffect(() => {
    const findItem = currentItem.items.find((itm) => itm.id === item.id);
    if (!findItem) return;

    setItemWithExtraPrice(findItem);
  }, [currentItem]);

  const handleToggle = (id) => () => {

    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };


  // update parent about selected addons
  useEffect(() => {
    const checkedAddons = item.addons.filter((addon) => checked.includes(addon.id));
    selectHandler(checkedAddons);
  }, [checked]);

  return (
    <List className={classes.root}>

      {item.addons.map((addon) => {
        const labelId = `checkbox-list-label-${addon.id}`;
        const showExtraPrice = itemWithExtraPrice.addons?.filter((addn) => addn.id === addon.id);
        let priceLength = 0
        if (showExtraPrice) {
          priceLength = showExtraPrice.length;
        }

        return (
          <ListItem className={classes.selectBox} key={addon.id} role={undefined} dense button onClick={handleToggle(addon.id)}>
            <Checkbox
              edge="start"
              color="primary"
              checked={checked.indexOf(addon.id) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
            <Box display="flex" justifyContent="space-between" width="100%">
              <div
                id={labelId}
                className={classes.addonName}
              >
                {addon.name}
              </div>
              <div className={classes.addonPrice}>

                {
                  priceLength !== 0
                  && (
                    <span>
                      {CURRENCY_SYMBOL}
                      {' '}
                      {showExtraPrice[0].add_price.toFixed(2)}
                    </span>
                  )
                }

              </div>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
}

SetMenuAddons.propTypes = {
  item: PropTypes.object.isRequired,
  selectHandler: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  cartAddons: state.cart.addons,
});

export default connect(mapStateToProps)(SetMenuAddons);
