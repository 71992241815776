import Slider from 'react-slick';
// import './ReviewSlider.style.scss';
import { Avatar, Box, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

const styles = {
  title: {
    fontFamily: '\'Sacramento\', sans-serif !important',
    fontSize: '50px !important',
    marginLeft: 3,
    textAlign: 'left',
    color: 'yellow',
    marginBottom: (theme) => theme.spacing(2),
  },
  body: {
    textAlign: 'left',
    fontSize: '35px !important',
  },
  authorSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(2),

  },
  authorDash: {
    color: (theme) => theme.palette.grey['50']
  },
  author: {
    marginLeft: (theme) => theme.spacing(1),
    fontSize: 25,
  }
};

function ReviewSlider({ ...otherProps }) {

  const sliderSetting = {
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const reviews = [
    {
      title: 'Good food & environment',
      body: `I order all the time lumb curry and lumb biriyani it's always very hot and
              fresh and the staff especially Ali are very friendly.`,
      author: 'Amrani Rene',
      // avatar: 'https://i.pinimg.com/originals/a3/c6/f3/a3c6f3a612d5399e6172a9a94a866d83.jpg'
    },
    {
      title: 'Good & fresh food',
      body: 'Abdul was great... I have eaten many saags and this was top notch- tender meat + good heat. Fresh. Price is reasonable for London',
      author: 'Harry Davies',
      // avatar: 'https://1.bp.blogspot.com/-ueF_FoX8ps0/XYC8fUKcCyI/AAAAAAAAAXU/AXiBbEkQRQ0EaVH5W1Ud5Xkgyaa7oQ4_gCLcBGAsYHQ/s640/Indian%2BBeautiful%2BGirls%252C%2BDownload%2BFree%2BGirl%2BPhotos%2B%252814%2529.jpg'
    },

  ];


  return (
    <Slider {...sliderSetting}>
      {
        reviews.map((review, idx) => (
          <Box key={idx}>
            <Typography
              sx={styles.title}
            >
              {review.title}
            </Typography>
            <Typography sx={styles.body} variant="body1">{review.body}</Typography>

            <Box sx={styles.authorSection}>
              {
                review.avatar
                  ? <Avatar alt="Comment Author" src={review.avatar} />
                  : <RemoveIcon sx={styles.authorDash} />
              }
              <Typography sx={styles.author}>{review.author}</Typography>
            </Box>
          </Box>
        ))
      }
    </Slider>
  );
}

export default ReviewSlider;
