import React, { useState } from 'react'
import ModalComp from './Modal/ModalComp';
import AddSetMenu from '../cart/addSetMenu';
import { Box, Button } from '@mui/material';
import AddToCartAddons from '../cart/addToCartAddons';
import { cartItemAdd, cartItemAddonsUpdate } from '../../store/cart/cartActions';
import { connect } from 'react-redux';

function PopupDesktop({
 item, updating, selectHandler, addToCartHandler, open, openHandler, title, ...otherProps
}) {

  const [showSetMenu, setShowSetMenu] = useState(false);

  const handleCloseSetMenuModal = () => {
    setShowSetMenu(false);
  }
  return (
    <ModalComp
      title={title}
      maxDesktopWidth="350px"
      minWidth="500px"
      open={open}
      hideFooter
      closeHandler={openHandler}
      closeOnBackdropClick
      disableFocusEnforcement
    >

      <Box>
        <AddToCartAddons
          item={item}
          updating={updating}
          selectHandler={selectHandler}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          onClick={addToCartHandler}
          disabled={otherProps.isOffDay && !otherProps.isTomorrowAvailable}
        >
          { otherProps.isOffDay && !otherProps.isTomorrowAvailable ? 'Today we\'re closed' : '' }
          { otherProps.isOffDay && updating ? 'Update Item' : 'Add To Cart' }
        </Button>
      </Box>

    </ModalComp>
  )
}

const mapStateToProps = (state) => ({
  isOffDay: state.opening.isOffDay,
  isSetMenuSelected: state.menu.isSetMenuSelected,
  setMenus: state.cart.set_menus,
  isTomorrowAvailable: (!state.opening.orderTiming.today && state.opening.orderTiming.hours.length),
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item, selectedAddon, isUpdate, index) => dispatch(cartItemAdd(item, selectedAddon, isUpdate, index)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupDesktop);
