import React from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { fontSize } from '@mui/system';

function Points() {

  return (
    <>

      <PageHeader pageName="Points" bgClass="breadcrumb-bg-services" />

      {/* features-4 */}

      <section className="grids-1 py-3">
        <div className="grids py-lg-5 py-md-4">
          <div className="container">
            <h1 className="text-center mb-3">How points works</h1>

            <Box className="col-lg-12 col-md-12 col-sm-12 pt-2" sx={{ fontSize: 25 }}>
              1. Order total's 10% will be converted to point as earned point (ex: £100 will get £10 as point)
              <br />
              2. £1 = 10 points
              <br />
              3. Points always a full number. It can't be a number with decimal (ex: 100 but 100.20 is not valid)
              <br />
              4. After successful order placement, used points immediately deducted from user points
              <br />
              5. When an order gets confirmed then points get added to customers account
              <br />
              6. Used points will be returned to the customer if order gets cancelled
              <br />
              7. If a confirmed order gets cancelled for any reason that order's earned points will be deducted
              <br />
              8. Points can be used to order when customer has 100 or more points

              <Typography fontSize={15} mt={3}>
                * Organization can change above points rules anytime without any notice.
                <br />
                * In case of any data loss or system migration all user points might get lost & organization will not be
                responsible for that.
              </Typography>
            </Box>

          </div>
        </div>
      </section>


    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,

});

export default connect(mapStateToProps)(Points);
