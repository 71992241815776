import PageHeader from '@/components/pageHeader/pageHeader';
import ThemeImage from '@/components/util/ThemeImage';
import Styles from '@/assets/css/custom.module.scss';
import SlideAnimation from '@/components/util/Animations/SlideAnimation';

function Awards() {

  return (
    <SlideAnimation in>
      <>
        <PageHeader pageName="Awards" bgClass="breadcrumb-bg-services" />
        {/* features-4 */}
        <section className="grids-1">
          <div className={`grids pt-3 pb-5 ${Styles.backgroundDark}`}>
            <div className="container">
              <h2 className="text-center exo2">Our Achievements</h2>
              <div className="row text-center grids-gap pt-4">

                <div className="col-lg-6 col-md-6 col-sm-12 mt-sm-0 mt-4">

                  <ThemeImage style={{ marginBottom: '20px' }} src="images/award1.jpg" className="img-fluid" />

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mt-sm-0 mt-4">

                  <ThemeImage style={{ marginBottom: '20px' }} src="images/award2.jpg" className="img-fluid" />

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mt-sm-0 mt-4">

                  <ThemeImage style={{ marginBottom: '20px' }} src="images/award3.jpg" className="img-fluid" />

                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mt-sm-0 mt-4">

                  <ThemeImage
                    style={{ marginBottom: '20px', width: '80%' }}
                    src="images/award4.jpg"
                    className="img-fluid"
                  />

                </div>

              </div>

            </div>
          </div>
        </section>
      </>
    </SlideAnimation>
  );
}

export default Awards;
