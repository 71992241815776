import ModalComp from '../util/Modal/ModalComp';
import SplitForm from '../order/stripePayment';
import { Grid } from '@mui/material';


function stripeModal({
  show, selectedPaymentMethod, resetPaymentMethodHandler, ...otherProps
}) {


  return (
    <ModalComp
      title="Make Payment"
      open={show}
      hideFooter
      closeHandler={resetPaymentMethodHandler}
      disableFocusEnforcement
    >
      <Grid>
        <SplitForm />

      </Grid>
    </ModalComp>
  );
}


export default stripeModal;
