import {
 Avatar, Box, Button, Paper, Typography
} from '@mui/material';
import UserAvatar from '../UserAvatar';
import { updateRequireAuth } from '@/store/user/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@/store';

const SessionExpire: React.FC = () => {

  const user = useSelector((state: IRootState) => state.user.data);
  const dispatch = useDispatch();

  const updateAuthentication = async () => {
    dispatch(updateRequireAuth(false));
  }

  return (
    <Box style={{ padding: 15 }}>

      <Box justifyContent="center" alignItems="center">
        <Avatar>
          <UserAvatar />
        </Avatar>
      </Box>

      <Box justifyContent="center" alignItems="center" style={{ marginBottom: 10 }}>
        <Paper square elevation={0}>
          <Typography variant="h5">
            {user.name}
          </Typography>
        </Paper>
      </Box>

      <Box style={{ marginBottom: 3 }}>

        <Paper square elevation={0}>
          <Typography variant="h5">
            {' '}
            Your session has expired
            <br />
            {' '}
            due to inactivity
          </Typography>
        </Paper>

      </Box>

      <Box justifyContent="center" alignItems="center">

        <Button
          type="button"
          className="point-link"
          variant="contained"
          color="primary"
          onClick={updateAuthentication}

        >
          Continue Again
        </Button>
      </Box>

    </Box>
  );
}

export default SessionExpire;
