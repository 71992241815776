import React, {
 useEffect, useMemo, useReducer, useState
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Box, Button, CircularProgress, InputLabel, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import {
  apiRequest, convertHourToTime, debugTime, formatDateTime, getCurrentClosingTime, getCurrentTime, getTomorrowDate
} from '@/util/util';
import { formSingleValidator, formValidator, validationRules } from '@/util/formValidation';
import useNotify from '@/hooks/useNotify';
import useSetting from '@/hooks/useSetting';
import { API_ADD_RESERVATON, RESERVATION_BUFFER_MIN, SETTING_FILE_PATH } from '@/util/constants';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';

const types = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};
const fields = {
  name: '',
  email: '',
  contact: '',
  persons: '',
  time: '',
  bookingDateTime: '',
  requirements: '',
  heardOn: '',
};
const defaultState = {
  loading: false,
  error: false,
  errorMsg: '',
  errorFields: { ...fields }
}

//handle address fields minimum errors
function reducer(state, { type, payload }) {
  switch (type) {
    case types.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case types.SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: payload
      }

    case types.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: (payload) || 'Something went wrong, please try again later'
      }

    case types.FIELD_ERROR:
      return {
        ...state,
        errorFields: {
          ...state.errorFields,
          ...payload
        }
      }

    default:
      return state;
  }
}

const styles = {
  circularProgress: {
    color: 'primary',
    size: '4rem',
    thickness: 1.6,
  },
};


function Reservation({ ...otherProps }) {

  const settings = useSetting([
    'reservation_img',
    'reservation_status',
    'reservation_disable_message',
    'reservation_buffer_start',
    'reservation_buffer_end'
  ]);

  const bufferMins = useMemo(() => {
    return {
      start: parseInt(settings.reservation_buffer_start ?? 0),
      end: parseInt(settings.reservation_buffer_end ?? 0)
    }
  }, [settings]);

  const [disable, setDisable] = useState(false);

  const [btnText, setBtnText] = useState('Reservation');
  const [formState, setFormState] = useState({ ...fields });

  const [reducerState, reducerDispatch] = useReducer(reducer, defaultState);

  // fill logged in user info in the form
  useEffect(() => {

    if (otherProps.user.data.name === '') return;

    setFormState((prevState) => ({
      ...prevState,
      name: otherProps.user.data.name,
      contact: otherProps.user.data.phone,
      email: otherProps.user.data.email,
    }));

  }, [otherProps.user]);


  const [notify] = useNotify();

  const validationSchema = {
    name: [
      validationRules.required(),
      validationRules.containOnlyAlphabet(),
      validationRules.min([6]),
      validationRules.max([30])
    ],
    email: [
      validationRules.required(),
      validationRules.email(),
    ],
    contact: [
      validationRules.required(),
      validationRules.containOnlyNumber(),
    ],
    persons: [
      validationRules.required(),
      validationRules.containOnlyNumber(),
      validationRules.persons(),
    ],
    requirements: [
      validationRules.min([8]),
      validationRules.max([50])
    ],
    heardOn: [
      validationRules.string(),
      validationRules.max([50])
    ]
  };

  const formHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormState({
      ...formState,
      [name]: value
    });


    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);


      reducerDispatch({
        type: types.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();
  }

  const [loading, setLoading] = useState(false);

  const [othersShow, setOthersShow] = useState(false);

  const [promotionalText, setPromotionalText] = useState('');

  const [imageLoader, setImageLoader] = useState(true);

  const [timePickerOpen, setTimePickerOpen] = useState(false);

  // useEffect(() => {
  //   console.log('open changed: ', timePickerOpen);
  // }, [timePickerOpen]);

  const [selectedDateTime, setSelectedDateTime] = useState(() => (
    otherProps.deliveryTime
      ? otherProps.deliveryTime
      : getCurrentTime()
  ));

  const dateChangeHandler = (dTime) => {
    // auto open timepicker
    setTimePickerOpen(true);
  }

  const selectedDaysHours = useMemo(() => {

    const selectedDayName = selectedDateTime.weekdayShort.toLowerCase();

    // set selected days hours
    const selectedDay = otherProps.opening.find((itm) => itm.day === selectedDayName);

    const hours = selectedDay ? selectedDay.hours : [{ from: '00:00:00', to: '23:59:59' }];

    return hours;

  }, [selectedDateTime, otherProps.opening]);

  const isToday = useMemo(() => {
    const currentTime = getCurrentTime();
    return selectedDateTime.hasSame(currentTime, 'day');
  }, [selectedDaysHours]);

  // change btn text for different conditions
  useEffect(() => {

    if (otherProps.isOffDay) {
      setBtnText('Restaurant closed today, reserve for next available days');
    }

    if (otherProps.isOffDay && !isToday) {
      setBtnText('Reservation');
    }

  }, [otherProps.isOpen, isToday, otherProps.isOffDay]);

  const currentTime = getCurrentTime(true);

  // disable based on current time
  useEffect(() => {

    const closingTimeArray = getCurrentClosingTime(selectedDaysHours);

    const closingTimes = closingTimeArray.filter((time) => time);

    const currentTimeWithBuffer = getCurrentTime(true)
      .plus({ minute: bufferMins.start });

    if (isToday) {

      // if current time with buffer exceed closing time of closed
      if (
        (currentTimeWithBuffer.toMillis() >= closingTimes?.[0]?.toMillis())
        || (!otherProps.isOpen && !otherProps.willOpen)
      ) {
        setDisable(true);
        setBtnText('Reservation has off for today, reserve for next available days');
      } else {

        setDisable(false);

        // if form is valid change btn text
        const btnText = formState.name && formState.contact && formState.persons && formState.email
        ? 'Submit' : 'Reservation';

        setBtnText(btnText);
      }
    } else {

      setDisable(false);
      setBtnText('Reservation');
    }

  }, [currentTime]);

  // disable if today is off day
  useEffect(() => {
    if (otherProps.isOffDay) {
      setDisable(true);
      setBtnText('Restaurant closed today, reserve for next available days')
    }
  }, []);

  // day names of all opening days
  const openingDays = useMemo(() => otherProps.opening.map((itm) => itm.day), [otherProps.opening]);

  /**
   * Disables invalid dates
   * @param day : DateTime
   * @returns {boolean}
   */
  const dayDisableHandler = (day) => {

    // enable all days if opening days not set
    if (openingDays.length === 0) return false;

    const dayName = day.weekdayShort.toLowerCase();

    // only enable days that have timing set
    return !openingDays.includes(dayName);
  };

  // handle heard on choose

  const handleOtherChoose = (event) => {
    setPromotionalText(event.target.value);
  };

  useEffect(() => {
    if (promotionalText === 'other') {
      setOthersShow(true);
    } else {
      setOthersShow(false);
    }

  }, [promotionalText]);

  // handle submit
  const submitHandler = async (e) => {

    e.preventDefault();

    // form validation
    const { error, data } = await formValidator(formState, validationSchema);

    if (error) {
      reducerDispatch({
        type: types.FIELD_ERROR,
        payload: data
      });
      return;
    }

    // time validation
    const isTimeValid = handleTimeSelection(selectedDateTime);
    if (!isTimeValid) return;

    if (formState.contact.toString().length < 7) {
      notify.warning('Contact Number should at least 7 digit');
      return;
    }

    if (!selectedDateTime) {
      notify.default('Please Select Reservation time');
      return;
    }

    let finalText = (promotionalText !== 'other') ? promotionalText : formState.heardOn;

    setLoading(true);
    try {

      const reservationData = ({
        name: formState.name,
        contact: formState.contact,
        persons: formState.persons,
        email: formState.email,
        note: formState.requirements,
        datetime: selectedDateTime.toFormat('yyyy-MM-dd HH:mm:ss'),
        found_on: finalText,
      });


      const response = await apiRequest.post(API_ADD_RESERVATON, reservationData);

      if (response.data.status) {
        setFormState({ ...fields });

        setLoading(false);
        notify.success('Reservation Successful. You will be notified shortly');

      } else {
        setLoading(false);
        notify.error(response.data.messages);
      }
    } catch (e) {
      setLoading(false);
      notify.error('Something wrong. Please try later');
    }
  }

  // show reservation image loader
  useEffect(() => {
    if (settings.reservation_img) {
      setImageLoader(false);
    } else {
      setImageLoader(true);
    }
  }, [settings]);

  /**
   * Validates if given time is valid reservation time
   * @param selectedDTime : DateTime
   * @return boolean
   */
  function validateSelectedTime(selectedDTime) {

    let isValidTime = false;
    const currentTime = getCurrentTime(true);

    selectedDaysHours.map((hour) => {
      let startTime = convertHourToTime(hour.from, selectedDTime);
      let endTime = convertHourToTime(hour.to, selectedDTime);

      // use current time as start time if current time > start time
      if (isToday && (currentTime.toMillis() >= startTime.toMillis())) startTime = currentTime;

      // apply buffer minutes

      startTime = startTime.plus({ minute: bufferMins.start });
      endTime = endTime.minus({ minute: bufferMins.end });

      // omit seconds for comparison
      const selectedTimeMills = selectedDTime.set({ second: 0, millisecond: 0 }).toMillis();

      isValidTime = (
        (selectedTimeMills >= startTime.toMillis())
        && (selectedTimeMills <= endTime.toMillis())
      );
    });

    return isValidTime;
  }

  function formatAvailableTime(timing) {
    const timeFormat = 'HH:mm';

    let startTime = convertHourToTime(timing.from, selectedDateTime);
    let endTime = convertHourToTime(timing.to, selectedDateTime);

    const currentTime = getCurrentTime(true);

    const closedMsg = 'We\'re closed, please choose another date.';

    // if time passed then show closed
    if (isToday && (currentTime.toMillis() >= endTime.toMillis())) {
      return closedMsg;
    }

    // use start time as current time if its greater
    if (currentTime.toMillis() > startTime.toMillis()) {
      startTime = currentTime;
    }

    // apply buffer minutes

    startTime = startTime.plus({ minute: bufferMins.start });
    endTime = endTime.minus({ minute: bufferMins.end });

    if (startTime.toMillis() >= endTime.toMillis()) return closedMsg;

    const startFormat = formatDateTime(startTime, timeFormat);
    const endFormat = formatDateTime(endTime, timeFormat);

    return `${startFormat} - ${endFormat}`;
  }

  const availableTimesText = useMemo(() => {
    return (
      <Box>
        <Box>
          Available Time:
          <span className="text-secondary">
            {
              (!isToday && selectedDaysHours.length)
                ? ` (${formatDateTime(selectedDateTime, 'MMM dd')})`
                : ''
            }
          </span>
        </Box>

        {
          selectedDaysHours.map((timing, idx) => (
            <Typography
              key={idx}
              color="primary"
            >
              {formatAvailableTime(timing)}
            </Typography>
          ))
        }
      </Box>
    );
  }, [timePickerOpen, selectedDateTime]);

  const handleTimeSelection = (dTime) => {
    const isValidTime = validateSelectedTime(dTime);

    if (!isValidTime) {
      notify.error('Selected time is invalid, Please choose valid time or another date');

      reducerDispatch({
        type: types.FIELD_ERROR,
        payload: {
          time: 'Invalid Date / Time'
        }
      });

    } else {
      reducerDispatch({
        type: types.FIELD_ERROR,
        payload: {
          time: ''
        }
      });
    }

    return isValidTime;
  };

  return (
    <>

      {/* features-4 */}
      <section className="w3l-services-6-main w3l-contact-2 reservationSection backgroundDark">
        <div className="services-6 pt-5 pb-3">
          <div className="container">

            <h2 className="text-center reservation-title" color="primary">
              <u> Book A Table Online </u>
            </h2>

            <div className="row serv_sec_info pt-5 pb-5">
              <div className="col-lg-6 col-md-6 col-sm-12 banner_bottom_grid help img-fluid radius-image">
                {
                  !imageLoader
                  && (
                    <img
                      src={SETTING_FILE_PATH + settings.reservation_img}
                      alt="Reservation"
                      className="img-fluid radius-image"
                    />
                  )
                }

                {
                  imageLoader
                  && (
                    <CircularProgress
                      sx={styles.circularProgress}
                    />
                  )
                }
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 banner_bottom_left">

                <div className="contact-right">
                  <Box component="form" noValidate onSubmit={submitHandler}>

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="name"
                      label="Full Name"
                      autoComplete="off"
                      id="name"
                      error={!!reducerState.errorFields.name}
                      helperText={reducerState.errorFields.name}
                      value={formState.name || ''}
                      onChange={formHandler}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="email"
                      label="Email Address"
                      autoComplete="off"
                      id="email"
                      error={!!reducerState.errorFields.email}
                      helperText={reducerState.errorFields.email}
                      value={formState.email || ''}
                      onChange={formHandler}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="contact"
                      label="Contact Number"
                      autoComplete="off"
                      id="contact"
                      error={!!reducerState.errorFields.contact}
                      helperText={reducerState.errorFields.contact}
                      value={formState.contact || ''}
                      onChange={formHandler}
                    />

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="persons"
                      label="Number of Persons"
                      autoComplete="off"
                      id="persons"
                      error={!!reducerState.errorFields.persons}
                      helperText={reducerState.errorFields.persons}
                      value={formState.persons || ''}
                      onChange={formHandler}
                    />

                    <Box display="flex" alignItems="baseline">

                      <Box ml={0} mr={2} my={2}>

                        <MobileDatePicker
                          renderInput={(params) => <TextField {...params} />}
                          label="Select Date"
                          id="date"
                          name="date"
                          value={selectedDateTime}
                          onChange={setSelectedDateTime}
                          minDate={getCurrentTime()}
                          shouldDisableDate={dayDisableHandler}
                          onAccept={dateChangeHandler}
                        />

                      </Box>

                      <Box my={1}>
                        <MobileTimePicker
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!reducerState.errorFields.time.length}
                              helperText={reducerState.errorFields.time}
                              onClick={() => setTimePickerOpen(true)}
                            />
                          )}
                          label="Select Time"
                          toolbarTitle={availableTimesText}
                          ampm={false}
                          minutesStep={5}
                          value={selectedDateTime}
                          open={timePickerOpen}
                          onChange={setSelectedDateTime}
                          onClose={() => {
                            setTimePickerOpen(false);
                          }}
                          onAccept={handleTimeSelection}
                        />
                      </Box>

                    </Box>

                    <TextField
                      type="text"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      name="requirements"
                      label="Any Special Requirements"
                      autoComplete="off"
                      id="requirements"
                      error={!!reducerState.errorFields.requirements}
                      helperText={reducerState.errorFields.requirements}
                      value={formState.requirements || ''}
                      onChange={formHandler}
                    />

                    <InputLabel id="demo-customized-select-label" className="reservation-hear-about-text">
                      Where did you hear about us?
                    </InputLabel>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      style={{ marginBottom: 15 }}
                      placeholder="Choose One"
                      value={promotionalText}
                      onChange={handleOtherChoose}
                    >
                      <MenuItem value="facebook">Facebook</MenuItem>
                      <MenuItem value="trip-advisor">Trip Advisor</MenuItem>
                      <MenuItem value="instagram">Instagram</MenuItem>
                      <MenuItem value="google search">Google Search</MenuItem>
                      <MenuItem value="recommendations">Recommendations</MenuItem>
                      <MenuItem value="newspaper">Newspaper</MenuItem>
                      <MenuItem value="Existing Customer">Existing Customer</MenuItem>
                      <MenuItem value="other">Others</MenuItem>
                    </Select>


                    {
                      othersShow && (
                        <TextField
                          type="text"
                          variant="outlined"
                          fullWidth
                          name="heardOn"
                          label="Where did you hear about us?"
                          autoComplete="off"
                          style={{ marginBottom: 15, marginTop: 10 }}
                          id="heardOn"
                          error={!!reducerState.errorFields.heardOn}
                          helperText={reducerState.errorFields.heardOn}
                          value={formState.heardOn || ''}
                          onChange={formHandler}
                        />
                      )
                    }

                    {
                      settings.reservation_status && (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={loading || disable}
                          margin="dense"
                          style={{ padding: 10, marginTop: 5 }}
                        >
                          {
                            loading
                            && <CircularProgress size={25} />
                          }
                          {btnText}
                        </Button>
                      )
                    }

                    {
                      !settings.reservation_status && (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled
                          margin="dense"
                          style={{ padding: 10, marginTop: 5 }}
                        >
                          {settings.reservation_disable_message}
                        </Button>
                      )
                    }

                  </Box>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  );

}

const mapStateToProps = (state) => ({
  user: state.user,
  opening: state.opening.data,
  deliveryTime: state.cart.delivery.time,
  isOffDay: state.opening.isOffDay,
  todayOpening: state.opening.orderTiming,
  isOpen: state.opening.isOpen,
  willOpen: state.opening.willOpen,
});

export default connect(mapStateToProps)(Reservation);
