import axios from 'axios';
import { API_BASE_URL, APP_TIMEZONE, AUTH_TOKEN_NAME } from './constants';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
// import store from '../store';
import _ from 'lodash';
import { updateOpeningHourState } from '@/store/opening/openingActions';
import { updateRequireAuth } from '@/store/user/userActions';
import WS from './ws';
import MainStore from './Classes/MainStore';
import { DateTime } from 'luxon';

export const DateFns = dateFns;
export const DateFnsTz = dateFnsTz;

export const STORE = MainStore.getStore();

export const getThemeStaticAsset = (assetPath) => `/static/theme/${assetPath}`;

export const apiRequest = axios.create({

  baseURL: API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'app': 'web',
  },
  withCredentials: true,
});

export const EchoInstance = WS.getInstance();


apiRequest.interceptors.request.use((config) => {

  // add access token to the request
  const accessToken = getFromLocalStorage(AUTH_TOKEN_NAME, false);
  config.headers.common['Authorization'] = `Bearer ${accessToken}`;

  return config;
});

apiRequest.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);


const responseHandler = (response) => response;

const errorHandler = async (error) => {
  if (error.response.status === 419) {
    STORE.dispatch(updateRequireAuth(true));
    // store.dispatch(updateRequireAuth(true));
  }
  return Promise.reject(error);
};

// export const getNextValidOpeningDate = () => {
//   const today = new Date();
//   const tomorrow = new Date();
//   let i;
//   for (i = 1; i <= 7; i++) {
//     tomorrow.setDate(today.getDate() + i)
//     const dayName = dateFns.format(tomorrow, 'EEE');
//     if (openingDays.includes(dayName.toLowerCase())) {
//       otherProps.setDeliveryDate(tomorrow);
//       setSelectedDate(applyTimeZone(new Date(tomorrow)));
//     }
//   }
// };

export const moneyFormat = (amount, fraction = 2) => parseFloat(amount.toFixed(fraction));

export const getDateFormat = (separator = '-') => `yyyy${separator}MM${separator}dd`;
export const getDateFormatAlt = (separator = '-', userMonthName = false) => (
  userMonthName
    ? 'MMM dd, yyyy'
    : `dd${separator}MM${separator}yyyy`
  );
export const getTimeFormat = () => 'hh:mm a';

/**
 * Formats date time
 * @param dateTime : DateTime
 * @param format : string
 * @returns string
 */
export const formatDateTime = (dateTime, format = '') => {
  if (!(dateTime instanceof DateTime)) {
    throw new Error('dateTime must be instance of DateTime');
  }

  const dateFormat = format.length ? format : getDateFormat();
  return dateTime.toFormat(dateFormat);
}

/**
 * Formats SQL datetime sting
 *
 * @param sqlDTimeStr : string - SQL string of date time
 * @param format : string
 * @return string
 */
export const formatDateTimeSQL = (sqlDTimeStr, format = '') => {
  if (typeof sqlDTimeStr !== 'string') {
    throw new Error(`sqlDTimeStr must be of type 'string' got: ${typeof sqlDTimeStr}`);
  }

  const dTime = DateTime.fromSQL(sqlDTimeStr);
  return formatDateTime(dTime, format);
}

export const mergeDateTime = (dateObj, timeObj) => {
  let timestampWithDate = dateFns.setHours(dateObj, timeObj.getHours())
    .setMinutes(timeObj.getMinutes(), timeObj.getSeconds());
return dateFns.toDate(timestampWithDate);
};

export function getTodaysTimeFromTimeString(timeString, getTimeStamp = false, timeSeparator = ':') {

  const timeObj = makeTimeFromTimeString(timeString, timeSeparator);

  if (getTimeStamp) {
    return timeObj.getTime();
  }

  const timeObjWithZone = applyTimeZone(dateFns.toDate(timeObj), APP_TIMEZONE);

  return timeObjWithZone;
}

/**
 * Converts time string (11:30:20) to Date object
 * @param timeString | String
 * @param timeSeparator | String
 * @returns {Date} | Date
 */
export function makeTimeFromTimeString(timeString, timeSeparator = ':') {
  if (typeof timeString !== 'string') {
    throw new Error(`Time string must be of type string, given: ${typeof timeString}`);
  }

  const [hour, minute, second] = timeString.split(timeSeparator).map((itm) => parseInt(itm));

  let timestamp = dateFns.setHours(new Date(), hour).setMinutes(minute, second || 0);

  return dateFns.toDate(timestamp);
}

export function applyTimeZone(dateObj, timezone = APP_TIMEZONE) {

  if (!(dateObj instanceof Date)) {
    throw new Error(`'dateObj' must be instance of 'Date', type of '${typeof dateObj}' given`);
  }

  return dateFnsTz.utcToZonedTime(dateObj, timezone);
}

export function formatWithTimeZone(dateObj, format, timeZone = APP_TIMEZONE) {

  if (!(dateObj instanceof Date)) {
    throw new Error(`'dateObj' must be instance of 'Date', type of '${typeof dateObj}' given`);
  }

  if (typeof format !== 'string') {
    throw new Error(`'format' must be a valid format string, type of '${typeof format}' given`);
  }

  const timeWithTimeZone = applyTimeZone(dateObj, timeZone);

  return dateFnsTz.format(timeWithTimeZone, format, { timeZone });

}

export function getTimeFormatFromTimeString(timeString, format = '', applyTimezone = true) {

  let timeObj = makeTimeFromTimeString(timeString);

  if (applyTimezone) {
    timeObj = applyTimeZone(timeObj, APP_TIMEZONE);
  }

  const finalFormat = format.length ? format : getTimeFormat();

  return dateFns.format(timeObj, finalFormat);
}

/**
 * Get hour set from hours that within current time
 * @param openingHours : object[]
 * @return object|null
 */
export function getCurrentHourSetFromOpeningHours(openingHours) {

  const currentTime = applyTimeZone(new Date()).getTime();

  const hourSet = openingHours.filter((hourSet) => (currentTime >= hourSet.from && currentTime <= hourSet.to));

  return hourSet[0] ?? null;
}

export function getCurrentClosingTime(selectedDaysHours) {
  const exactTime = selectedDaysHours.map((hours) => {
    const fromTime = convertHourToTime(hours.from);
    const toTime = convertHourToTime(hours.to);
    const currentTime = getCurrentTime();
    if (currentTime.toMillis() >= fromTime.toMillis() && currentTime.toMillis() <= toTime.toMillis()) {
      return toTime;
    }
    return false;
  });
  return exactTime;
}

export const getValidOpeningStatus = (hours) => {

  // don't try to calculate if hours unavailable
  if (_.isEmpty(hours)) return false;

  const currentTimestamp = applyTimeZone(new Date(), APP_TIMEZONE).getTime();

  // convert timings to timestamps
  const validTimings = hours.filter((hour) => {
    const fromTimestamp = getTodaysTimeFromTimeString(hour.from, true);
    const toTimestamp = getTodaysTimeFromTimeString(hour.to, true);
    return ((currentTimestamp >= fromTimestamp) && (currentTimestamp <= toTimestamp));
  });

  const openingState = !!validTimings.length;

  // update store
  STORE.dispatch(updateOpeningHourState(openingState));
  // store.dispatch(updateOpeningHourState(openingState));

  return openingState;
}

export const convertTimeTo12Hour = (hours) => {

  let convertedHour;

  if (parseInt(hours) === 24 || parseInt(hours) === 0) {
    convertedHour = hours;
  }
  if (parseInt(hours) > 12) {
    convertedHour = parseInt(hours) - 12;
  } else {
    convertedHour = parseInt(hours);
  }
  // console.log('convert hour', convertedHour);
  return convertedHour.toString();
}


/***
 *
 * Use when time string in ISO format
 * Used in showing time in Order History Page exm: 10:30 AM
 * @param timeString : String
 * @param [format = do MMM, yyyy] : String
 * @param [timeZone = null]: String
 * @returns String
 *
 */

export const formatISODate = (timeString, format = 'do MMM, yyyy', timeZone = null) => {

  let timeDate = dateFns.parseJSON(timeString);

  if (timeZone !== null) {
    timeDate = DateFnsTz.utcToZonedTime(timeDate, timeZone);
  }

  return dateFns.format(timeDate, format);
};


/**
 *
 * @param {String} key
 * @param {Boolean} decode
 * @returns {any}
 */
export function getFromLocalStorage(key, decode = true) {

  const value = window.localStorage.getItem(key);

  return decode
    ? JSON.parse(value)
    : value;
}

/**
 *
 * @param {String} key
 * @param {Boolean} decode
 * @returns {any}
 */
export function pullFromLocalStorage(key, decode = true) {

  const value = getFromLocalStorage(key, decode);

  window.localStorage.removeItem(key);

  return value;
}

/**
 *
 * @param {String} key
 * @param {any} value
 * @param {Boolean} encode
 * @returns {any}
 */
export function putToLocalStorage(key, value, encode = true) {

  const finalValue = encode
    ? JSON.stringify(value)
    : value;
window.localStorage.setItem(key, finalValue);

  return finalValue;
}

export function getTomorrowDate() {
  const today = DateTime.now();

  return today.plus({
    day: 1
  });
}

/**
 * Converts opening days hours to DateTime Object
 * @param openingDay : Object
 * @param dateForTimes : DateTime this date will be used & times will be attached to it
 * @return Array[Object]
 */
export function convertOpeningHoursToTime(openingDay, dateForTimes = DateTime.now()) {
  return openingDay.hours.map((hour) => {

    const fromDate = convertHourToTime(hour.from, dateForTimes);
    const toDate = convertHourToTime(hour.to, dateForTimes);

    return {
      from: fromDate,
      to: toDate,
      delivery: hour.enabled_delivery,
      collection: hour.enabled_collection
    }
  });
}

/**
 * Converts SQL like time (17:15:25) to DateTime Object
 * @param hourString : string
 * @param date : DateTime
 * @param noMilliseconds : boolean
 * @returns {DateTime}
 */
export function convertHourToTime(hourString, date = DateTime.now(), noMilliseconds = true) {

  const parsedDTime = DateTime.fromSQL(hourString);

  if (!parsedDTime.isValid) {
    throw new Error(`Invalid hour string '${hourString}' provided.`);
  }

  return date.set({
    hour: parsedDTime.hour,
    minute: parsedDTime.minute,
    second: parsedDTime.second,
    millisecond: noMilliseconds ? 0 : parsedDTime.millisecond,
  });
}

/**
 * Formats full date object for debug purpose
 * @param dTime : DateTime
 * @param use24hr : boolean
 * @return string
 */
export function debugTime(dTime, use24hr = false) {

  if (!(dTime instanceof DateTime)) {
    throw new Error(`'dTime' must be instance of DateTime, got: ${typeof dTime}`);
  }

  const formats = {
    year: 'yyyy',
    month: 'MM',
    date: 'dd',
    hour12: 'hh',
    hour24: 'HH',
    minute: 'mm',
    second: 'ss',
    ampm: 'a'
  };

  let format = `${formats.date}-${formats.month}-${formats.year} `;
  format += use24hr ? formats.hour24 : formats.hour12;
  format += `:${formats.minute}:${formats.second}`;
  format += use24hr ? '' : ` ${formats.ampm}`;

  return dTime.toFormat(format);
}

/**
 * Get setting keys out of the state
 * @param settingState : object
 * @param settingKeys : string[]
 * @param valueOnly : boolean
 * @return {{ string: any; }}
 */
export function getSettingEntries(settingState, settingKeys, valueOnly = true) {

  if (!Array.isArray(settingKeys)) {
    throw new Error('Setting keys must be an array');
  }

  const result = {};

  settingKeys.map((itm) => {

    let value = null;

    if (settingState.hasOwnProperty(itm)) {
      value = valueOnly ? settingState[itm].value : settingState[itm];
    }

    result[itm] = value;
  });

  return result;
}

/**
 * Get current time
 * @returns {DateTime}
 */
export function getCurrentTime(removeSecAndMs = false) {

  const now = DateTime.now();
  return removeSecAndMs ? getTimeWithoutSecsAndMs(now) : now;
}

/**
 * Sets second & millisecond to 0 of a given DateTime object
 * @param dTime : DateTime
 * @return DateTime
 */
export function getTimeWithoutSecsAndMs(dTime) {

  return dTime.set({
    second: 0,
    millisecond: 0
  });
}
