import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';

import { OfferState } from '@/store/offer/offerReducer';

//only for development
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));


//for production
// const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

// export interface State {
//   user: any,
//   layout: any,
//   url: any,
//   cart: any,
//   opening: any,
//   setting: any,
//   seo: any,
//   menu: any,
//
//   offer: OfferState,
// }


export type IRootState = ReturnType<typeof rootReducer>;
