import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { footerShowHide, headerShowHide } from '../../store/layout/layoutActions';
import { connect } from 'react-redux';
import './Error500Page.scss';

const Error404Page = (props) => {

  // hide/show header footer
  useEffect(() => {

    // hiding header footer
    toggleHeaderFooter(false);

    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  const toggleHeaderFooter = (show) => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }

  return (

    <Box className="error-container">

      <div id="upSide">
        <h1 className="error-code">500</h1>
      </div>
      <div id="downSide">
        <h2 className="error-msg">Page Crashed!</h2>
        <p className="error-description my-2">We already been informed & on the way to fix.</p>
        <a
          className="btn btn-outline-secondary my-2 back-btn"
          href="/"
        >
          GO BACK HOME
        </a>
      </div>

    </Box>

  );
}

const mapDispatchToProps = (dispatch) => ({
  showHideHeader: (shouldShow) => dispatch(headerShowHide(shouldShow)),
  showHideFooter: (shouldShow) => dispatch(footerShowHide(shouldShow))
});

export default connect(null, mapDispatchToProps)(Error404Page);
