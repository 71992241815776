import React, { useEffect, useState } from 'react';
import { useSpring, animated, AnimatedComponent } from '@react-spring/web';

type NumberAnimationProps = {
  number: number,
  decimal?: number,
  component?: React.ElementType
};

function NumberAnimation({
  number,
  decimal = 0,
  component
}: NumberAnimationProps) {

  const [num, setNum] = useState({ prev: 0, current: 0 });

  const animationProps = useSpring({ val: num.current, from: { val: num.prev } });

  // update number
  useEffect(() => {
    setNum((prevState) => ({
      prev: prevState.current,
      current: number
    }));
  }, [number]);

  const formatNumber = (num: number) => {
    if (decimal < 1) {
      return Math.floor(num);
    }

    return num.toFixed(decimal);
  };

  const Component: AnimatedComponent<React.ElementType> = (component === undefined)
    ? animated.div
    : animated(component);

  return (
    <Component>
      {animationProps.val.to(formatNumber)}
    </Component>
  );

}

export default NumberAnimation;
