import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { connect } from 'react-redux';
import { cartItemAdd, cartItemAddonsUpdate, cartItemRemove } from '../../store/cart/cartActions';

import CartItemAddonsPopup from './cartItemAddonsPopup';
import useNotify from '../../hooks/useNotify';

import {
 Menu, MenuItem, ListItemIcon, ListItemText, Box, IconButton
} from '@mui/material';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.grey['300'],
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

function CartItemMenu({
  item, index, qty, ...otherProps
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [notify] = useNotify();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addItemHandler = () => {
    otherProps.cartAddItem(item, item?.selected_addon);
    notify.success('Item Quantity Increased');
  }


  const removeItemHandler = () => {

    const itemQtyInCart = otherProps.cartItems.find((itm) => itm.id === item.id).qty;

    // remove item addons if item gets completely removed from cart
    if (itemQtyInCart < 2) {
      otherProps.cartUpdateItemAddon(item, item?.selected_addon);
    }

    otherProps.cartRemoveItem(item, item?.selected_addon);
    notify.warning('Item Quantity Decreased');
  }

  return (
    <Box>
      <IconButton
        aria-label="menu"
        size="small"
        onClick={handleClick}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={1}
          borderColor="primary.main"
          borderRadius="5px"
        >
          <Box ml="8px" fontWeight="900" fontSize="20px">{qty}</Box>
          <Box>
            <MoreVertIcon color="secondary" />
          </Box>
        </Box>
      </IconButton>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={addItemHandler}>
          <ListItemIcon>
            <AddIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Add" />
        </StyledMenuItem>
        <StyledMenuItem onClick={removeItemHandler}>
          <ListItemIcon>
            <RemoveIcon color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Remove" />
        </StyledMenuItem>

        {/* display addons menu only when item has addons*/}
        {
          !!item.addons.length
          && (
            <CartItemAddonsPopup
              item={item}
              index={index}
              updating
              openCallback={handleClose}
            >
              {
                (showToggler) => (
                  <StyledMenuItem onClick={showToggler}>
                    <ListItemIcon>
                      <EditIcon color="secondary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Addon" />
                  </StyledMenuItem>
                )
              }
            </CartItemAddonsPopup>
          )
        }

      </StyledMenu>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items
});

const mapDispatchToProps = (dispatch) => ({
  cartAddItem: (item, addon) => dispatch(cartItemAdd(item, addon)),
  cartRemoveItem: (item, addon) => dispatch(cartItemRemove(item, addon)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemMenu);
