import {
  SETTING_GET_REQUEST,
  SETTING_GET_FAIL,
  SETTING_GET_SUCCESS
} from './settingTypes';
import { apiRequest } from '../../util/util';
import { API_GET_SETTING } from '../../util/constants';

///// Login
export const settingRequest = () => ({
    type: SETTING_GET_REQUEST
  });

export const settingRequestFail = (err) => ({
    type: SETTING_GET_FAIL,
    payload: err
  });

export const settingRequestSuccess = (settings) => ({
    type: SETTING_GET_SUCCESS,
    payload: settings
  });

export const getSetting = () => async (dispatch) => {

    dispatch(settingRequest());

    try {

      // get setting from api
      const settings = await apiRequest.get(API_GET_SETTING);

      dispatch(settingRequestSuccess(settings.data?.data));

    } catch (e) {
      dispatch(settingRequestFail(e.response?.data));
    }

  };
