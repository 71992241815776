type ThemeImageOwnProps = {
  src: string;
};

type ThemeImageProps = ThemeImageOwnProps & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export default function ThemeImage({ src, ...attrs }: ThemeImageProps) {
  const fullPath = `/static/theme/${src}`;
  return <img src={fullPath} {...attrs} alt="" />
}

