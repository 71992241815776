import Echo from 'laravel-echo';

// don't delete this
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js';

import { apiRequest } from './util';
import { WS_AUTHORIZATION } from './constants';

// Singleton
const WS = ((() => {

  let instance;

  function createInstance() {

    const options = {
      broadcaster: 'pusher',
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      encrypted: true,
      forceTLS: true,
      authorizer: (channel, options) => ({
        authorize: (socketId, callback) => {
          apiRequest
            .post(WS_AUTHORIZATION, {
              socket_id: socketId,
              channel_name: channel.name
            })
            .then((response) => callback(false, response.data))
            .catch((error) => callback(true, error))
          ;
        }
      }),
    };

    return new Echo(options);
  }

  return {
    getInstance() {
      if (!instance) {
        instance = createInstance();
      }

      return instance;
    }
  };
})());

export default WS;
