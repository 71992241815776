import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#850a21',
      // dark: '#BA0512',
    },
    secondary: {
      main: '#b2985a',
      // dark: '#B50603'
    },
    customStatus: {
      main: red['900'],
    },
    colorDark: {
      main: '#141414',
    },
    customDanger: {
      main: '#CA0101',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  background: {
    lightYellow: 'antiquewhite'
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});
