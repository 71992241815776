import React, { ForwardedRef, forwardRef } from 'react';
import { useSpring, animated } from '@react-spring/web';

type FadeAnimationProps = {
  in: boolean;
  children: React.ReactNode;
  onEnter?: () => void;
  onExited?: () => void;
}

const FadeAnimation = forwardRef((
  {
     in: open,
    children,
    onEnter = () => {},
    onExited = () => {},
    ...otherProps
  }: FadeAnimationProps,
  ref: ForwardedRef<HTMLDivElement>
) => {

  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => (open && onEnter) && onEnter(),
    onRest: () => (!open && onExited) && onExited(),
  });

  return (
    <animated.div
      ref={ref}
      style={style}
      {...otherProps}
    >
      {children}
    </animated.div>
  );
});

export default FadeAnimation;
