import {
  FIRST_ARRIVAL,
  FOOTER_HIDE,
  FOOTER_SHOW,
  HEADER_HIDE,
  HEADER_SHOW,
  SET_TIME_OUT_REF,
  SHOW_AUTO_OFFER_POPUP,
  SHOW_LEFT_SIDE_BAR,
  SHOW_PAYMENT_MODAL,
  SHOW_PROMOTIONAL_POPUP
} from './layoutTypes';


export const headerShowHide = (show = true) => ({
    type: show ? HEADER_SHOW : HEADER_HIDE
  });

export const footerShowHide = (show = true) => ({
    type: show ? FOOTER_SHOW : FOOTER_HIDE
  });

export const updateShowPromotionalPopup = (payload) => ({
    type: SHOW_PROMOTIONAL_POPUP,
    payload,
  });

export const updateShowPaymentModal = (payload) => ({
    type: SHOW_PAYMENT_MODAL,
    payload,
});

export const updateFirstArrival = (payload) => ({
    type: FIRST_ARRIVAL,
    payload,
  })

export const updateShowAutoOfferPopup = (payload) => ({
    type: SHOW_AUTO_OFFER_POPUP,
    payload,
  });

export const updateShowLeftSideBar = (payload) => ({
    type: SHOW_LEFT_SIDE_BAR,
    payload,
  })

export const updateSetTimeOutRef = (payload) => ({
    type: SET_TIME_OUT_REF,
    payload,
})

