import { useSelector } from 'react-redux';
import { IRootState } from '@/store';

type Settings = {
  [key: string]: any
}

/**
 * Get settings from state
 * @param settingKeys : string[]
 * @param valueOnly : boolean
 * @return any
 */
function useSetting(settingKeys: string[], valueOnly: boolean = true) {

  if (!Array.isArray(settingKeys)) {
    throw new Error('Setting keys must be an array');
  }

  const settings = useSelector((state: IRootState) => state.setting.data);

  const result: Settings = {};

  settingKeys.map((itm: string) => {

    let value = null;

    if (settings.hasOwnProperty(itm)) {
      value = valueOnly ? settings[itm].value : settings[itm];
    }

    result[itm] = value;
  });

  return result;
}

export default useSetting;
