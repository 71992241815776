import { useEffect } from 'react';
import { Typography } from '@mui/material';

type RedirectToProps = {
  fullUrl: string,
  showRedirectingText?: boolean
};

export default function RedirectTo({ fullUrl, showRedirectingText = false }: RedirectToProps) {

  useEffect(() => {
    window.location.replace(fullUrl);
  }, []);

  return showRedirectingText ? <Typography>Redirecting...</Typography> : null;
}
